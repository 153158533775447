import React from 'react'

// we do this because purge css is a pile of shit
const sizes = {
  0: 'text-lg md:text-xl lg:text-2xl xl:text-3xl tracking-tightest',
  1: 'text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-1 tracking-tightest',
  2: 'text-3xl md:text-3xl lg:text-4xl xl:text-5xl mb-2 tracking-tighter',
  3: 'text-3xl md:text-4xl lg:text-5xl xl:text-6xl mb-3 tracking-tighter',
  4: 'text-4xl md:text-5xl lg:text-6xl xl:text-7xl mb-4 tracking-tight',
  5: 'text-5xl md:text-6xl lg:text-7xl xl:text-8xl mb-5 tracking-tight'
}

const ResponsiveHeader = ({ className, size = 4, children, ...props }) => (
  <h1 className={`font-heading font-bold -ml-0.5 md:-ml-1 ${sizes[size]} ${className || ''}`} {...props}>
    {children}
  </h1>
)

export default ResponsiveHeader
