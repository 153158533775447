import React from 'react'
import Layout from '../components/layout/layout'
import PageHeader from '../components/pageheader'

const NotFoundPage = () => {
  return (
    <Layout>
      <PageHeader title="Who we are" />
      <p className="mb-6">This is Top Dog Studios which is owned by Darkport Technologies Limited, a company regsitered in the UK, company number 12522448, whose registered office address is 71-75 Shelton Street Covent Garden London WC2H 9JQ</p>
    </Layout>
  )
}

export default NotFoundPage
