import React from 'react'

import ResponsiveHeader from './responsiveheader'
import SEO from './seo'

const PageHeader = ({ title, subTitle, withDivider = false, size = 4, className = 'my-12' }) => (
  <div className={className}>
    <SEO title={title} description={subTitle} />
    <ResponsiveHeader size={size}>{title}</ResponsiveHeader>

    <p className="font-medium text-lg lg:text-xl text-gray-300">{subTitle}</p>

    {withDivider &&
      <div className="my-12">
        <div className="border-t border-gray-200"></div>
      </div>
    }
  </div>
)

export default PageHeader
