import React from 'react'
import { Helmet } from 'react-helmet'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, bodyClasses = '' }) => (
    <>
        <Helmet bodyAttributes={{ class: `antialiased bg-black text-white max-w-full overflow-x-hidden ${bodyClasses}` }} />

        <div className="flex flex-col min-h-screen">
            <Header />

            <main className="flex-grow mt-32 contained py-8 md:py-16">
                {children}
            </main>
            
            <Footer />
        </div>
    </>
)

export default Layout
